<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/agent.png" alt="" />
        <span class="center">代理管理</span>
        <img src="../../assets/right.png" alt="" />
        <span class="center">代理企业业绩统计</span>
      </div>
      <div style="text-align:right;">
        <el-radio-group v-model="type" class="radio-btn">
          <el-radio-button label="列表">列表</el-radio-button>
          <el-radio-button label="图表">图表</el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <el-card>
      <ListFilter>
        <div class="summary">
          <span>代理企业：</span>
          <el-select
            size="mini"
            v-model="name"
            placeholder="请选择代理企业"
            @change="personChange"
            style="width:300px"
            filterable
          >
            <el-option
              :label="item.name"
              :value="item.name"
              v-for="(item, i) in enterpriseList"
              :key="i"
            >
            </el-option>
          </el-select>
          <span> 时间范围：</span>
          <el-radio-group v-model="dateRadio" @change="radioChange()">
            <el-radio-button label="今天" size="mini"></el-radio-button>
            <el-radio-button label="本周" size="mini"></el-radio-button>
            <el-radio-button label="本月" size="mini"></el-radio-button>
            <el-radio-button label="本年" size="mini"></el-radio-button>
            <el-radio-button label="自定义" size="mini"></el-radio-button>
          </el-radio-group>

          <el-date-picker
            v-if="dateRadio === '自定义'"
            :clearable="false"
            @blur="timeCustomer()"
            v-model="dateTime"
            type="daterange"
            size="mini"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
      </ListFilter>

      <el-tabs v-model="type" class="tabs-none">
        <el-tab-pane name="列表">
          <el-table :data="list">
            <el-table-column label="代理企业名称">
              <template slot-scope="scope">
                {{ scope.row.enterprise }}
              </template>
            </el-table-column>
            <el-table-column label="发薪总金额(元)">
              <template slot-scope="scope">
                {{ parseFloat(scope.row.total - scope.row.serviceFee) | money }}
              </template>
            </el-table-column>
            <el-table-column label="交易总金额（元）">
              <template slot-scope="scope">
                {{ scope.row.total | money }}
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane name="图表">
          <div
            ref="echartBox"
            style="width: calc(100vw - 320px); height: 400px;"
          ></div>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import { compact } from 'lodash';
import moment from 'moment';
import { api } from '/src/api/base';
export const agentEnterpriseTotalList = api()(
  'agent.enterprise.total.list.json',
);
export const agentEnterpriseList = api()('agent.enterprise.list.json');
export default {
  components: {
    ListFilter: () => import('/src/components/filter'),
  },
  data() {
    return {
      type: '列表',
      enterpriseList: [],
      path: '',
      originList: [],
      list: [],
      name: '全部',
      agentPath: '',
      dateRadio: '本月',
      dateTime: null,
      startTime: '',
      endTime: '',
    };
  },
  async created() {
    await this.getTransferList();
    this.startTime = moment()
      .month(moment().month())
      .startOf('month')
      .format('YYYY-MM-DD HH:mm:ss');
    this.endTime = moment()
      .month(moment().month())
      .endOf('month')
      .format('YYYY-MM-DD HH:mm:ss');
    await this.getData();
    this.initChart();
  },

  methods: {
    initChart() {
      var myChart = window.echarts.init(this.$refs.echartBox);
      // 指定图表的配置项和数据
      var option = {
        title: {
          text: this.list.length === 0 ? '暂无数据' : '',
          x: 'center',
          y: 'center',
          textStyle: {
            color: '#666',
            fontWeight: 'normal',
            fontSize: 15,
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          formatter: function(params) {
            return (
              params[0].name + '</br>总交易额：' + (params[0].value || 0) + '元'
            );
          },
        },
        xAxis: {
          name: '代理企业名称',
          type: 'category',
          data: this.list.map((it) => it.enterprise),
          axisLabel: {
            rotate: 0,
          },
        },
        yAxis: {
          name: '交易总金额（元）',
          type: 'value',
        },
        series: [
          {
            data: this.list.map((it) => it.total),
            type: 'bar',
            barWidth: '30',
            barMinHeight: '5',
            label: {
              show: true,
              position: 'top',
              color: '#666666',
              fontStyle: 'normal',
              fontWeight: 'normal',
            },
          },
        ],
      };
      myChart.setOption(option);
    },
    async getTransferList() {
      const { list } = await agentEnterpriseList({
        pageNumber: 1,
        pageSize: 1000,
      });
      this.enterpriseList = [
        {
          name: '全部',
        },
        ...list,
      ];
      this.agentPath = compact(
        this.enterpriseList.map((it) => it.originAgentPath),
      ).join(',');
    },
    async getData() {
      const res = await agentEnterpriseTotalList({
        agentPath: this.agentPath,
        startTime: this.startTime,
        endTime: this.endTime,
      });
      this.originList = res;
      this.list = res;
    },
    async personChange() {
      if (this.name === '全部') {
        this.list = this.originList;
      } else {
        this.list = this.originList.filter((it) => it.enterprise === this.name);
      }
      this.initChart();
    },
    async radioChange() {
      if (this.dateRadio === '今天') {
        this.startTime = moment()
          .startOf('day')
          .format('YYYY-MM-DD HH:mm:ss');
        this.endTime = moment()
          .endOf('day')
          .format('YYYY-MM-DD HH:mm:ss');
        await this.getData();
        this.initChart();
      }
      if (this.dateRadio === '本周') {
        this.startTime = moment()
          .week(moment().week())
          .startOf('week')
          .format('YYYY-MM-DD HH:mm:ss');
        this.endTime = moment()
          .week(moment().week())
          .endOf('week')
          .format('YYYY-MM-DD HH:mm:ss');
        await this.getData();
        this.initChart();
      }
      if (this.dateRadio === '本月') {
        this.startTime = moment()
          .month(moment().month())
          .startOf('month')
          .format('YYYY-MM-DD HH:mm:ss');
        this.endTime = moment()
          .month(moment().month())
          .endOf('month')
          .format('YYYY-MM-DD HH:mm:ss');
        await this.getData();
        this.initChart();
      }
      if (this.dateRadio === '本年') {
        this.startTime = moment()
          .year(moment().year())
          .startOf('year')
          .format('YYYY-MM-DD HH:mm:ss');
        this.endTime = moment()
          .year(moment().year())
          .endOf('year')
          .format('YYYY-MM-DD HH:mm:ss');
        await this.getData();
        this.initChart();
      }
    },
    async timeCustomer() {
      this.startTime = moment(this.dateTime[0])
        .startOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      this.endTime = moment(this.dateTime[1])
        .endOf('day')
        .format('YYYY-MM-DD HH:mm:ss');
      await this.getData();
      this.initChart();
    },
  },
};
</script>
<style>
.tabs-none .el-tabs__nav-wrap::after,
.tabs-none .el-tabs__header {
  height: 0;
}
.radio-btn .el-radio-button__inner {
  padding: 6px 15px;
}
.el-radio-button__inner {
  padding: 7px 20px !important;
  font-size: 12px !important;
  color: #606266;
}
.el-radio-button:first-child .el-radio-button__inner,
.el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0;
}
.el-range-editor--mini.el-input__inner {
  top: 1px;
}
</style>
<style lang="scss" scoped></style>
